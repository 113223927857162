import React from "react"
import { graphql } from "gatsby"

import { SEO, Slideshow } from "../components"
import { isServer, convertSlideshowQuery } from "../utilities"
import { useRealViewportUnits } from "../hooks"

import { SlideshowQueryData, SlideshowTemplateContext } from "../types"

interface Props {
  pageContext: SlideshowTemplateContext
  data: SlideshowQueryData
  location: Window["location"]
}

const SlideshowTemplate = ({ data, location }: Props) => {
  useRealViewportUnits()

  const { photos } = convertSlideshowQuery({ data })

  return (
    <>
      <SEO
        images={photos.map(photo => photo.feature)}
        meta={[
          {
            property: "og:type",
            content: "website",
          },
        ]}
        title="Home"
        path={location.pathname}
      />
      {isServer() ? null : (
        <Slideshow showHeader title="Film Photography" items={photos} />
      )}
    </>
  )
}

export default SlideshowTemplate

export const query = graphql`
  query Slideshow {
    contentfulCollection(title: { eq: "Main" }) {
      images {
        id
        handle
        excerpt {
          excerpt
        }
        product
        title
        image {
          description
          fluid(quality: 50, maxHeight: 2000, maxWidth: 2000) {
            srcSet
            srcSetWebp
            src
            srcWebp
          }
          file {
            details {
              image {
                height
                width
              }
            }
          }
        }
        previewPhotos {
          image {
            fluid(quality: 50, maxHeight: 2000, maxWidth: 2000) {
              src
              srcSet
              srcSetWebp
              srcWebp
            }
            file {
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
    allShopifyProductVariant {
      edges {
        node {
          shopifyId
          priceV2 {
            amount
            currencyCode
          }
          availableForSale
        }
      }
    }
  }
`
